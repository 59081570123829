<template>
    <div id="carinoutColumn" :style="{ width: '100%', height: '100%' }"></div>
</template>
<script>
export default {
    props: {
        id: String,
    },
    components: {},
    mounted() {
        this.draw();
    },
    data() {
        return {
            random: Math.round(Math.random() * 80 + 20),
        };
    },
    methods: {
        draw() {
            var app = {};
            var chartDom = document.getElementById("carinoutColumn");
            var myChart = this.$echarts.init(chartDom, "dark");
            window.addEventListener("resize", function () {
                myChart.resize();
            });
            const categories = (function () {
                let now = new Date();
                let res = [];
                let len = 12;
                while (len--) {
                    let date = now.toLocaleTimeString().replace(/^\D*/, "")
                    let newDate = date.substring(0, date.length - 5) + '00'
                    res.unshift(newDate);
                    now = new Date(+now - 3600000);
                }
                return res;
            })();
            const data = (function () {
                let res = [];
                let len = 12;
                while (len--) {
                    res.push(Math.round(Math.random() * 1000));
                }
                return res;
            })();
            const data2 = (function () {
                let res = [];
                let len = 12;
                while (len--) {
                    res.push(Math.round(Math.random() * 1000));
                }
                return res;
            })();
            var option = {
                backgroundColor: "",
                tooltip: {
                    trigger: "axis",
                    axisPointer: {
                        // 坐标轴指示器，坐标轴触发有效
                        type: "shadow", // 默认为直线，可选为：'line' | 'shadow'
                    },
                },
                grid: {
                    left: "2%",
                    right: "4%",
                    bottom: "10%",
                    top: "20%",
                    containLabel: true,
                },
                legend: {
                    data: ["驶入", "驶出"],
                    right: 10,
                    top: 12,
                    textStyle: {
                        color: "#fff",
                    },
                    itemWidth: 12,
                    itemHeight: 10,
                    // itemGap: 35
                },
                xAxis: {
                    type: "category",
                    data: categories,
                    axisLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(255,255,255,0.12)",
                        }
                    },
                    axisLabel: {
                        // interval: 0,
                        // rotate: 40,
                        color: "#07DBFF",
                    },
                },

                yAxis: {
                    type: "value",
                    max: "1000",
                    axisLine: {
                        show: false,
                        lineStyle: {
                            color: "white",
                        },
                    },
                    splitLine: {
                        show: true,
                        lineStyle: {
                            color: "rgba(255,255,255,0.12)",
                        },
                    },
                    axisLabel: {
                        // interval: 0,
                        // rotate: 40,
                        color: "#07DBFF",
                    },
                },
                series: [
                    {
                        name: "驶入",
                        type: "bar",
                        barWidth: "10px",
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: "#89F0C5",
                                    },
                                    {
                                        offset: 1,
                                        color: "#3ABD90",
                                    },
                                ]),
                                barBorderRadius: 12,
                            },
                        },
                        data: data,
                    },
                    {
                        name: "驶出",
                        type: "bar",
                        barWidth: "10px",
                        itemStyle: {
                            normal: {
                                color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                    {
                                        offset: 0,
                                        color: "#49FCED",
                                    },
                                    {
                                        offset: 1,
                                        color: "#3AB7F7",
                                    },
                                ]),
                                barBorderRadius: 11,
                            },
                        },
                        data: data2,
                    },
                ],
            };
            app.count = 10;
            setInterval(function () {
                let axisData = new Date().toLocaleTimeString().replace(/^\D*/, "");
                data.shift();
                data.push(Math.round(Math.random() * 1000));
                data2.shift();
                data2.push(Math.round(Math.random() * 1000));
                categories.shift();
                categories.push(axisData);
                myChart.setOption({
                    xAxis: [
                        {
                            data: categories,
                        },
                    ],
                    series: [
                        {
                            data: data,
                        },
                        {
                            data: data2,
                        }
                    ],
                });
            }, 360000);
            option && myChart.setOption(option);
        },
    },
};
</script>
<style lang="scss" scoped>
</style>