<template>
	<div 
		class="container"
		v-loading="loading"
		element-loading-text="数据加载中..."
		element-loading-background="rgba(0, 0, 0, 0.6)"
	>
		<div class="topContainer">
			<div class="top-left">
				<div class="title">
					<span>客流数据</span>
				</div>
				<div style="padding: 0px 30px">
					<div>今日客流（人）</div>
					<div class="flex align-center" style="padding: 1rem 2rem">
						<RollNum
							:number="passengerFlow.todayPassengerFlow"
							:step="0"
						></RollNum>
						<div style="margin-left: 20px">
							<div>环比昨日</div>
							<div
								class="flex align-center"
								:class="yesterdayRate >= 0?'col-rise':'col-decline'"
								style="font-size: 16px; font-weight: bold; "
							>
								{{  (yesterdayRate * 100).toFixed(2) + '%' }}
								<img
									style="width: 12px; height: 19px; margin-left: 8px"
									:src="
										yesterdayRate >= 0
											? require('../../assets/image/index05.png')
											: require('../../assets/image/index04.png')
									"
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
				<div style="padding: 0px 30px">
					<div>本周客流（人）</div>
					<div class="flex align-center" style="padding: 1rem 2rem">
						<RollNum
							:number="passengerFlow.weekPassengerFlow"
							:step="0"
						></RollNum>
						<div style="margin-left: 20px">
							<div>环比上周</div>
							<div
								class="flex align-center"
								:class="lastWeekRate >= 0?'col-rise':'col-decline'"
								style="font-size: 16px; font-weight: bold;"
							>
								{{ (lastWeekRate * 100).toFixed(2) + '%' }}
								<img
									style="width: 12px; height: 19px; margin-left: 8px"
									:src="
										lastWeekRate >= 0
											? require('../../assets/image/index05.png')
											: require('../../assets/image/index04.png')
									"
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="top-left">
				<div class="title">
					<span>今日防疫数据统计</span>
				</div>
				<div style="width: 100%; height: 80%">
					<RankData 
						:data="epidemic" 
					></RankData>
				</div>
			</div>
			<div class="top-left">
				<div class="title">
					<span>车辆数据</span>
				</div>
				<div style="padding: 0px 30px">
					<div>今日车辆（辆）</div>
					<div class="flex align-center" style="padding: 1rem 2rem">
						<RollNum :number="2150" :step="10"></RollNum>
						<div style="margin-left: 20px">
							<div>环比昨日</div>
							<div
								class="flex align-center"
								style="font-size: 16px; font-weight: bold; color: #e8a713"
							>
								增长18.93%
								<img
									style="width: 12px; height: 19px; margin-left: 8px"
									src="../../assets/image/index05.png"
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
				<div style="padding: 0px 30px">
					<div>本周车辆（辆）</div>
					<div class="flex align-center" style="padding: 1rem 2rem">
						<RollNum :number="36565" :step="10"></RollNum>
						<div style="margin-left: 20px">
							<div>环比上周</div>
							<div
								class="flex align-center"
								style="font-size: 16px; font-weight: bold; color: #13e8e5"
							>
								减少8.93%
								<img
									style="width: 12px; height: 19px; margin-left: 8px"
									src="../../assets/image/index04.png"
									alt=""
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="midContainer">
			<div class="midConItem">
				<div class="title">
					<span>当前景区数据</span>
				</div>
				<div class="itemCon">
					<div class="numCon">
						<div class="topItem1">
							<div class="numText">{{scenic.scenicComfortableCount}}</div>
						</div>
						<div class="btmText">舒适景区</div>
					</div>
					<div class="numCon">
						<div class="topItem2">
							<div class="numText">{{scenic.scenicNormalCount}}</div>
						</div>
						<div class="btmText">正常景区</div>
					</div>
					<div class="numCon">
						<div class="topItem3">
							<div class="numText">{{scenic.scenicCongestionCount
}}</div>
						</div>
						<div class="btmText">拥堵景区</div>
					</div>
					<div class="numCon">
						<div class="topItem4">
							<div class="numText">{{scenic.hotScenic
}}</div>
						</div>
						<div class="btmText">热门景区</div>
					</div>
				</div>
			</div>
			<div class="midConItem">
				<div class="title">
					<span>当前监控信息</span>
				</div>
				<div class="topCon">
					<div class="flex align-center flex-direction">
						<div style="position: relative">
							<div class="circleItemL"></div>
							<div class="circleText" style="color: #f25959">在线</div>
						</div>
						<span
							class="margin-left-xs text-bold"
							style="font-size: 28px; color: #00d8ff"
							>{{monitorData.onlineCount}}<span style="font-size: 14px; color: #ffffff">台</span></span
						>
					</div>
					<div class="flex align-center flex-direction">
						<div style="position: relative">
							<div class="circleItemM"></div>
							<div class="circleText" style="color: #ff9e2c">警告</div>
						</div>
						<span
							class="margin-left-xs text-bold"
							style="font-size: 28px; color: #00d8ff"
							>0<span style="font-size: 14px; color: #ffffff">台</span></span
						>
					</div>
					<div class="flex align-center flex-direction">
						<div style="position: relative">
							<div class="circleItemR"></div>
							<div class="circleText" style="color: #52ccff">离线</div>
						</div>
						<span
							class="margin-left-xs text-bold"
							style="font-size: 28px; color: #00d8ff"
							>{{monitorData.offlineCount}}<span style="font-size: 14px; color: #ffffff">台</span></span
						>
					</div>
				</div>
			</div>
			<div class="midConItem">
				<div class="title">
					<span>当前车位数据</span>
				</div>
				<div class="itemCon" style="padding: 0px 15%">
					<div class="numCon">
						<div class="topItem1">
							<div class="numText">4</div>
						</div>
						<div class="btmText">使用中车位</div>
					</div>
					<div class="numCon">
						<div class="topItem2">
							<div class="numText">6</div>
						</div>
						<div class="btmText">闲置车位</div>
					</div>
					<div class="numCon">
						<div class="topItem3">
							<div class="numText">40%</div>
						</div>
						<div class="btmText">车位占用率</div>
					</div>
				</div>
			</div>
		</div>
		<div class="bottomContainer">
			<div class="bottomConItem">
				<div class="title">
					<span>景区客流时段分析</span>
				</div>
				<div style="width: 100%; height: 80%">
					<DynamicData :data="scenicPassengerFlowPeriod"></DynamicData>
				</div>
			</div>
			<div class="bottomConItem">
				<div class="title">
					<span>车辆出入分析</span>
				</div>
				<div style="width: 100%; height: 80%">
					<CarinoutData></CarinoutData>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
// import TodayPerson from "./components/todayPerson.vue";
// import ScenicData from "./components/scenicData.vue";
// import HolidayData from "./components/holidayData.vue";
import DynamicData from './components/dynamicData.vue'
import RankData from './components/rankData.vue'
import CarinoutData from './components/carinoutData.vue'
import RollNum from '../../components/public/rollNum.vue'
export default {
	components: {
		// TodayPerson,
		// ScenicData,
		// HolidayData,
		DynamicData,
		RankData,
		CarinoutData,
		RollNum,
	},
	mounted() {
		this.init()
		// this.loading = false		//关闭加载提示
		setInterval(() => {
			this.init()
		}, 1000*60*2);
		
	},
	data() {
		return {
			dvCode: '36118101',
			passengerFlow: {
				todayPassengerFlow: 0,
				weekPassengerFlow: 0,
				yesterdayPassengerFlow: 0,
				lastWeekPassengerFlow: 0,
			},
			scenic: {
				scenicComfortableCount: 0,
				scenicNormalCount: 0,
				scenicCongestionCount: 0,
				hotScenic: '',
			},
			epidemic:{
				greenCode:0,
				redCode:0,
				yellowCode:0
			},
			scenicPassengerFlowPeriod:{},
			monitorData:{
				onlineCount:0,
				warnCount:0,
				offlineCount:0
			},
			loading:false,
		}
	},
	computed: {
		yesterdayRate: function () {
			if(this.passengerFlow.yesterdayPassengerFlow==0){
				return 0
			}
			return isNaN(
				(this.passengerFlow.todayPassengerFlow -
					this.passengerFlow.yesterdayPassengerFlow) /
					this.passengerFlow.yesterdayPassengerFlow
			)
				? 0
				: (this.passengerFlow.todayPassengerFlow -
						this.passengerFlow.yesterdayPassengerFlow) /
						this.passengerFlow.yesterdayPassengerFlow
		},

		lastWeekRate: function () {
			if(this.passengerFlow.lastWeekPassengerFlow == 0){
				return 0
			}
			return isNaN(
				(this.passengerFlow.weekPassengerFlow -
					this.passengerFlow.lastWeekPassengerFlow) /
					this.passengerFlow.lastWeekPassengerFlow
			)
				? 0
				: (this.passengerFlow.weekPassengerFlow -
						this.passengerFlow.lastWeekPassengerFlow) /
						this.passengerFlow.lastWeekPassengerFlow
		},
	},
	methods: {
		init(){
			this.getPassengerFlowData()
			this.getScenicData()
			this.getEpidemicData()
			this.getScenicPassengerFlowPeriod()
			this.getMonitorList()
		},
		//获取客流数据
		async getPassengerFlowData() {
			let r = await this.$api.getPassengerFlowData()
			if (r.code == 0) {
				this.passengerFlow = r.data
				console.log(this.passengerFlow)
			}
		},
		//获取当前景区数据
		async getScenicData() {
			let r = await this.$api.getScenicData()
			if(r.code == 0){
				this.scenic = r.data
			}
			console.log(r)
		},
		//获取防疫数据
		async getEpidemicData(){
			let r= await this.$api.getEpidemicData()
			if(r.code == 0){
				this.epidemic = r.data
				this.epidemicLoading = false
				console.log(this.epidemic)
			}
		},
		//获取景区客流时段分析
		async getScenicPassengerFlowPeriod(){
			let r= await this.$api.getScenicPassengerFlowPeriod()
			if(r.code ==0){
				this.scenicPassengerFlowPeriod = r.data.scenicHoursCount
			}
		},
		//获取监控信息
		getMonitorList() {
			let params = {
				dvCode: this.dvCode
			}
			this.$api.getMonitorData(params).then(r=>{
				r.data.forEach(item=>{
					if(item.status==0){
						this.monitorData.offlineCount ++
					}else if(item.status==1){
						this.monitorData.onlineCount ++
					}
				})
			})
			
			
		},
		
	},
}
</script>
<style lang="scss" scoped>
.container {
	width: 100%;
	height: 100%;
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	color: #ffffff;
	font-size: 14px;
	padding: 0.5% 1.6% 1% 1.6%;

	.baseCell {
		width: 100%;
		height: 36px;
		background-image: url('../../assets/image/common05.png');
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: 0px 0px;
		display: flex;
		align-items: center;
	}

	.title {
		width: 100%;
		height: 50px;
		padding: 0px 50px;
		font-size: 18px;
		font-weight: 400;
		color: #17caf0;
		display: flex;
		align-items: center;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			top: 18px;
			left: 25px;
			width: 14px;
			height: 14px;
			background: #17caf0;
			opacity: 0.3;
			border-radius: 50%;
		}

		&::after {
			content: '';
			position: absolute;
			top: 21px;
			left: 28px;
			width: 8px;
			height: 8px;
			background: #17caf0;
			border-radius: 50%;
		}
	}

	.conTop {
		width: 32.5%;
		height: 100%;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: 0px 0px;
	}

	.conBottom {
		width: 100%;
		height: 100%;
		background-size: 100% 100%;
		background-repeat: no-repeat;
		background-position: 0px 0px;
	}

	.topContainer {
		width: 100%;
		height: 33%;
		box-sizing: border-box;
		display: flex;
		justify-content: space-between;

		.top-left {
			@extend .conTop;
			background-image: url('../../assets/image/index01.png');

			.box-item {
				position: relative;
				height: 52px;
				font-size: 36px;
				font-weight: bold;
				color: #00d8ff;
				line-height: 36px;
				text-align: center;
				list-style: none;
				writing-mode: vertical-lr;
				text-orientation: upright;
				/*文字禁止编辑*/
				-moz-user-select: none;
				/*火狐*/
				-webkit-user-select: none;
				/*webkit浏览器*/
				-ms-user-select: none;
				/*IE10*/
				-khtml-user-select: none;
				/*早期浏览器*/
				user-select: none;
				/* overflow: hidden; */
			}

			/*滚动数字设置*/
			.number-item {
				width: 47px;
				height: 52px;
				background: #ccc;
				list-style: none;
				margin-right: 10px;
				background: url('../../assets/image/person06.png');
				background-size: 100% 100%;
				background-repeat: no-repeat;
				background-position: 0px 0px;

				& > span {
					position: relative;
					display: inline-block;
					margin-right: 10px;
					width: 100%;
					height: 100%;
					writing-mode: vertical-rl;
					text-orientation: upright;
					overflow: hidden;

					& > i {
						font-style: normal;
						position: absolute;
						top: 8px;
						left: 50%;
						transform: translate(-50%, 0);
						transition: transform 1s ease-in-out;
						letter-spacing: 10px;
					}
				}
			}

			.number-item:last-child {
				margin-right: 0;
			}
		}
	}

	.midContainer {
		width: 100%;
		height: 28%;
		display: flex;
		justify-content: space-between;

		.midConItem {
			width: 32.5%;
			height: 100%;
			background-image: url('../../assets/image/index02.png');
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: 0px 0px;

			.topCon {
				width: 100%;
				display: flex;
				justify-content: space-between;
				padding: 0 6%;

				.circleItem {
					width: 115px;
					height: 115px;
					background-size: 100% 100%;
					background-repeat: no-repeat;
					background-position: 0px 0px;
					animation: rotateVbtn 1s linear infinite;

					span {
						animation: rotateRe 1s linear infinite;
					}

					@keyframes rotateVbtn {
						0% {
							transform: rotate(0);
						}

						100% {
							transform: rotate(360deg);
						}
					}

					@keyframes rotateRe {
						0% {
							transform: rotate(360deg);
						}

						100% {
							transform: rotate(0);
						}
					}
				}

				.circleItemL {
					@extend .circleItem;
					background-image: url('../../assets/image/monitor01.png');
					color: #f25959;
				}

				.circleItemM {
					@extend .circleItem;
					background-image: url('../../assets/image/monitor02.png');
					color: #ff9e2c;
				}

				.circleItemR {
					@extend .circleItem;
					background-image: url('../../assets/image/monitor03.png');
					color: #52ccff;
				}

				.circleText {
					width: 100%;
					height: 100%;
					display: flex;
					justify-content: center;
					align-items: center;
					position: absolute;
					top: 0px;
					left: 0px;
					font-size: 26px;
					font-weight: 400;
				}
			}

			.itemCon {
				width: 100%;
				height: 75%;
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0px 40px;

				.numCon {
					width: 25%;
					display: flex;
					flex-direction: column;
					align-items: center;

					.topItem {
						width: 98px;
						height: 58px;
						background-size: 100% 100%;
						background-repeat: no-repeat;
						background-position: 0px 0px;
						display: flex;
						flex-direction: column;
						align-items: center;
						font-size: 14px;
						font-weight: 400;
						color: #ffffff;
						margin: 5px 30px;

						.btmText {
							width: 71px;
							font-size: 16px;
							font-weight: bold;
							text-align: center;
							font-weight: bold;
							color: #ffffff;
							margin-top: 40px;
							
						}
					}

					.topItem1 {
						@extend .topItem;
						background-image: url('../../assets/image/person01.png');

						.numText {
							width: 71px;
							font-size: 26px;
							font-weight: bold;
							background: linear-gradient(0deg, #2ae58b 0%, #0ab391 100%);
							color: #ffffff;
							background-clip: text;
							-webkit-text-fill-color: transparent;
							text-align: center;
							margin-top: -10px;
						}
					}

					.topItem2 {
						@extend .topItem;
						background-image: url('../../assets/image/person02.png');

						.numText {
							width: 71px;
							font-size: 26px;
							font-weight: bold;
							background: linear-gradient(0deg, #27e0f6 0%, #0b8fb2 100%);
							color: #ffffff;
							background-clip: text;
							-webkit-text-fill-color: transparent;
							text-align: center;
							margin-top: -10px;
						}
					}

					.topItem3 {
						@extend .topItem;
						background-image: url('../../assets/image/person03.png');

						.numText {
							width: 71px;
							font-size: 26px;
							font-weight: bold;
							background: linear-gradient(0deg, #3e8bff 0%, #3365b8 100%);
							color: #ffffff;
							background-clip: text;
							-webkit-text-fill-color: transparent;
							text-align: center;
							margin-top: -10px;
						}
					}

					.topItem4 {
						@extend .topItem;
						background-image: url('../../assets/image/person10.png');

						.numText {
							width: 71px;
							font-size: 18px;
							font-weight: bold;
							background: linear-gradient(0deg, #fdda65 0%, #ae8a1b 100%);
							color: #ffffff;
							background-clip: text;
							-webkit-text-fill-color: transparent;
							text-align: center;
							margin-top: -5px;
							white-space: nowrap;

						}
					}
				}
			}
		}
	}

	.bottomContainer {
		width: 100%;
		height: 36%;
		display: flex;
		justify-content: space-between;

		.bottomConItem {
			width: 49%;
			height: 100%;
			background-image: url('../../assets/image/index03.png');
			background-size: 100% 100%;
			background-repeat: no-repeat;
			background-position: 0px 0px;
		}
	}

	
}
.col-rise{
	color:#e8a713 ;
}
.col-decline{
	color: #13e8e5 ;
}
</style>