<template>
  <div id="main" :style="{ width: '100%', height: '100%' }"></div>
</template>
<script>
export default {
  props: {
    data:Object
  },
  components: {},
  mounted() {
    this.childData = this.data
    this.draw()
  },
  data() {
    return {
      random: Math.round(Math.random() * 80 + 20),
      childData:null,  //原始数据
      categories:[],
      dataList:[
        // {
        // name:'',
        // data:[]
        // }
      ],        //处理完的数据
    };
  },
  watch:{
    data(val){
        this.dataList=[]
        this.childData = val
        this.draw()
    }
  },
  methods: {
    
    draw() {
      let chartDom = document.getElementById("main");
      let myChart = this.$echarts.init(chartDom, "dark");
      let option;
      
      setTimeout(() => {
        window.onresize = function () {
          // 自适应大小
          myChart.resize();
        };
      }, 100);
      
      for (const i in this.childData) {
        let data=[]
        let categories = []
        for(const j in this.childData[i]){
          data.push(this.childData[i][j])
          categories.push(j.split(' ')[1])
          this.categories = categories
        }
        this.dataList.push({
          name:i,
          data:data
        })
      }
      
      console.log(this.dataList)
      
      option = {
        backgroundColor: "",
        title: {
          text: "",
        },
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#283b56",
            },
          },
        },
        legend: {
          top: "3%",
        },
        toolbox: {
          show: false,
          feature: {
            dataView: { readOnly: false },
            restore: {},
            saveAsImage: {},
          },
        },
        grid: {
          top: "16%",
          left: "5%",
          right: "5%",
          bottom: "20%",
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100,
        },
        xAxis: [
          {
            type: "category",
            boundaryGap: false,
            data: this.categories,
            axisLabel: {
              textStyle: {
                color: "#07DBFF",
              },
            },
            axisTick: {
              alignWithLabel: true,
            },
          },
        ],
        yAxis: [
          {
            type: "value",
            scale: true,
            name: "",
            axisLabel: {
              textStyle: {
                color: "#07DBFF",
              },
            },
          },
        ],
        series: [],
      };
      

      let colorList = [
        {
          itemColor:'#02FFC6',
          areaColor:['rgba(2, 255, 198,0.8)','rgba(2, 255, 198,0.3)']
        },
        {
          itemColor:'#62E5F8',
          areaColor:['rgba(58, 183, 233,0.8)','rgba(58, 183, 233,0.3)']
        },
        {
          itemColor:'#67C23A',
          areaColor:['rgba(103,194,58,0.8)','rgba(103,194,58,0.3)']
        },
        {
          itemColor:'#f0ad4e',
          areaColor:['rgba(240,173,78,0.8)','rgba(240,173,78,0.3)']
        },
        {
          itemColor:'#dd524d',
          areaColor:['rgba(221,82,77,0.8)','rgba(221,82,77,0.3)']
        },
        
      ]
      this.dataList.forEach((item,index)=>{
        option.series.push(
          {
            name: item.name,
            type: "line",
            smooth: true,
            textStyle: {
              color: "#07DBFF",
            },
            symbol: "circle",
            symbolSize: 5,
            sampling: "average",
            itemStyle: {
              color: colorList[index%5].itemColor,
            },
            areaStyle: {
              color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: colorList[index%5].areaColor[0],
                },
                {
                  offset: 1,
                  color: colorList[index%5].areaColor[1],
                },
              ]),
            },
            data: item.data,
          }
        )
      })
      
      option && myChart.setOption(option);
    },
  },
};
</script>
<style lang="scss" scoped>
</style>