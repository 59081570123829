<template>
	<div class="box-item">
		<li
			:class="{ 'number-item': !isNaN(item) }"
			v-for="(item, index) in orderNum"
			:key="index"
		>
			<span v-if="!isNaN(item)">
				<i ref="numberItem">0123456789</i>
			</span>
		</li>
	</div>
</template>
<script>
export default {
	props: {
		number: Number,
		step: {
			type: Number,
			default: 5,
		},
	},
	components: {},
	mounted() {
		this.newNumber = this.number
		this.toOrderNum(this.number)
		this.setNumberTransform()
		this.increaseNumber()
	},
	data() {
		return {
			orderNum: ['0', '0', '0', '0', '0', '0'],
			newNumber: null,
		}
	},
	watch: {
        //监听数据变化,重新初始化
		number(val) {
			this.newNumber = val
			this.toOrderNum(this.number)
			this.setNumberTransform()
			this.increaseNumber()
		},
	},
	methods: {
		// 定时增长数字
		increaseNumber() {
			let self = this
			this.timer = setInterval(() => {
				let random = this.getRandomNumber(0, this.step)
				self.newNumber = self.newNumber + random
				this.toOrderNum(self.newNumber) // 这里输入数字即可调用
				self.setNumberTransform()
			}, 5000)
		},
		getRandomNumber(min, max) {
			return Math.floor(Math.random() * (max - min + 1) + min)
		},
		// 设置文字滚动
		setNumberTransform() {
			const numberItems = this.$refs.numberItem // 拿到数字的ref，计算元素数量
			const numberArr = this.orderNum.filter((item) => !isNaN(item))
			// 结合CSS 对数字字符进行滚动,显示订单数量
			for (let index = 0; index < numberItems.length; index++) {
				const elem = numberItems[index]
				elem.style.transform = `translate(-50%, -${numberArr[index] * 10}%)`
			}
		},
		// 处理总订单数字
		toOrderNum(num) {
			num = num.toString()
			// 把订单数变成字符串
			if (num.length < 6) {
				num = '0' + num // 如未满八位数，添加"0"补位
				this.toOrderNum(num) // 递归添加"0"补位
			} else if (num.length === 6) {
				this.orderNum = num.split('') // 将其便变成数据，渲染至滚动数组
			} else {
				// 订单总量数字超过八位显示异常
				console.log('订单总量数字过大，显示异常，请联系客服')
			}
		},
	},
}
</script>
<style lang="scss" scoped>
.box-item {
	position: relative;
	height: 52px;
	font-size: 36px;
	font-weight: bold;
	color: #00d8ff;
	line-height: 36px;
	text-align: center;
	list-style: none;
	writing-mode: vertical-lr;
	text-orientation: upright;
	/*文字禁止编辑*/
	-moz-user-select: none;
	/*火狐*/
	-webkit-user-select: none;
	/*webkit浏览器*/
	-ms-user-select: none;
	/*IE10*/
	-khtml-user-select: none;
	/*早期浏览器*/
	user-select: none;
	/* overflow: hidden; */
}

/*滚动数字设置*/
.number-item {
	width: 47px;
	height: 52px;
	background: #ccc;
	list-style: none;
	margin-right: 10px;
	background: url('../../assets/image/person06.png');
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: 0px 0px;

	& > span {
		position: relative;
		display: inline-block;
		margin-right: 10px;
		width: 100%;
		height: 100%;
		writing-mode: vertical-rl;
		text-orientation: upright;
		overflow: hidden;

		& > i {
			font-style: normal;
			position: absolute;
			top: 8px;
			left: 50%;
			transform: translate(-50%, 0);
			transition: transform 1s ease-in-out;
			letter-spacing: 10px;
		}
	}
}

.number-item:last-child {
	margin-right: 0;
}
</style>